import React from "react"
import styled, { keyframes } from "styled-components"
import { Page } from "../../shared/Page/Page"
import { FlatButton } from "../../shared/Ui/FlatButton/FlatButton"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { TextButton } from "../../shared/Ui/TextButton/TextButton"
import media from "css-in-js-media"
import { Helmet } from "react-helmet"

const ANIM_GEM_SPACING = `100rem`

const ContainerAnimation = keyframes`
  0% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-12deg) rotateY(-15deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }

  25% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(7deg) rotateY(12deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }
  75% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-10deg) rotateY(12deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }
  100% {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-12deg) rotateY(-15deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
  }
`

const Gem1Counter = keyframes`
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(0deg);
  }
  95% {
    transform: rotate(-365deg);
  }
  100% {
    transform: rotate(-365deg);
  }
`

const BackAnim = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  17% {
    opacity: 0;
  }
  20% {
    transform: scale(0);
    opacity: 1;
  }
  25% {
    transform: scale(1);
    opacity: 0;
  }
  28% {
    transform: scale(0);
    opacity: 0;
  }
  29% {
    transform: scale(0);
    opacity: 1;
  }
  30% {
    transform: scale(0);
    opacity: 1;
  }
  35% {
    transform: scale(1);
    opacity: 0;
  }
  38% {
    transform: scale(0);
    opacity: 0;
  }
  39% {
    transform: scale(0);
    opacity: 1;
  }
  40% {
    transform: scale(0);
    opacity: 1;
  }
  45% {
    transform: scale(1);
    opacity: 0;
  }
  48% {
    transform: scale(0);
    opacity: 0;
  }
  49% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(0);
    opacity: 1;
  }
  55% {
    transform: scale(1);
    opacity: 0;
  }
  58% {
    transform: scale(0);
    opacity: 0;
  }
  59% {
    transform: scale(0);
    opacity: 1;
  }
  60% {
    transform: scale(0);
    opacity: 1;
  }
  65% {
    transform: scale(1);
    opacity: 0;
  }
  68% {
    transform: scale(0);
    opacity: 0;
  }
  69% {
    transform: scale(0);
    opacity: 1;
  }
  70% {
    transform: scale(0);
    opacity: 1;
  }
  75% {
    transform: scale(1);
    opacity: 0;
  }
  78% {
    transform: scale(0);
    opacity: 0;
  }
  79% {
    transform: scale(0);
    opacity: 1;
  }
  80% {
    transform: scale(0);
    opacity: 1;
  }
  85% {
    transform: scale(1);
    opacity: 0;
  }
  88% {
    transform: scale(0);
    opacity: 0;
  }
  89% {
    transform: scale(0);
    opacity: 1;
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: rotate(0);
  }
`

const HeroAnimationPhaseOne = keyframes`
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(1);
  }
  14% {
    transform: scale(1);
  }
  15% {
    transform: scale(0.9);
  }
  17% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 0;
    transform: scale(0);
  }
  95% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const HeroAnimationPhaseTwo = keyframes`
  0% {
    opacity: 0;
    transform: rotate(0);
  }
  17% {
    opacity: 0;
    transform: scale(0) rotate(-40deg);
  }
  20% {
    opacity: 1;
    transform: scale(1) rotate(0deg);

  }
  95% {
    opacity: 1;
    transform: scale(1) rotate(360deg);
  }
  100% {
    opacity: 0;
    transform: scale(0) rotate(400deg);
  }
`

const Container = styled.div`
    section.top-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5rem;

        ${media("<desktop")} {
            justify-content: center;
        }
    }

    section.hero {
        display: flex;
        align-items: stretch;
        justify-content: center;
        padding: 120rem 0 200rem;

        ${media("<tablet")} {
            padding-bottom: 80rem;
        }

        .left {
            flex: 1;

            h1 {
                font-size: 55rem;
                font-family: "Gilroy-Bold", sans-serif;
                color: ${({ theme }) => theme.text.dark};
                font-weight: unset;
                margin: 0;

                span {
                    background: -webkit-linear-gradient(#60a5fa, #818cf8);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            h2 {
                font-weight: normal;
                font-size: 20rem;
                color: rgb(76, 87, 115);
                line-height: 35rem;
                max-width: 490rem;
            }

            ${media("<phone")} {
                > h1:not(.desktop) {
                    font-size: 35rem;
                }

                > h2:not(.desktop) {
                    font-size: 20rem;
                }
            }
        }

        .right {
            flex: 1;
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 30rem;
            position: relative;
            perspective: 2000rem;
            perspective-origin: 50% 50%;
            overflow: hidden;

            ${media("<tablet")} {
                display: none;
            }

            .back {
                position: absolute;
                top: 30rem;
                bottom: 30rem;
                left: 0;
                right: 0;
                margin: auto;
                width: 200rem;
                height: 200rem;
                opacity: 0;
                background: #bfdbfe;
                border-radius: 90%;
                transform-origin: center;
                animation: ${BackAnim} infinite 8s;
                animation-timing-function: linear;
                animation-delay: 3s;
            }

            .anim-container {
                position: relative;
                z-index: 50;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                animation: ${ContainerAnimation} infinite 15s;

                .phase-one {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    animation: ${HeroAnimationPhaseOne} infinite 8s;
                    animation-delay: 3s;

                    .start-button {
                        display: flex;
                        align-items: center;
                        font-family: "Gilroy-SemiBold", sans-serif;
                        font-size: 20rem;
                        padding: 15rem 25rem;
                        background: ${({ theme }) => theme.primary};
                        border-radius: 10rem;
                        color: #fff;
                    }
                }

                .phase-two {
                    opacity: 0;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    animation: ${HeroAnimationPhaseTwo} infinite 8s;
                    animation-timing-function: linear;
                    animation-delay: 3s;
                    transform-origin: center center;
                    width: 300rem;
                    height: 300rem;

                    .gem {
                        position: absolute;

                        margin: auto;
                        transform-origin: center;
                        animation: ${Gem1Counter} infinite 8s;
                        animation-timing-function: linear;
                        animation-delay: 3s;

                        &.gem-1 {
                            top: 0;
                            left: 0;
                            right: 0;
                        }

                        &.gem-2 {
                            top: 0;
                            right: 0;
                            bottom: 0;
                        }

                        &.gem-3 {
                            left: 0;
                            right: 0;
                            bottom: 0;
                        }

                        &.gem-4 {
                            top: 0;
                            left: 0;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }

    section.quick-feats {
        display: flex;
        align-items: center;
        margin: 0 -20rem;
        padding: 70rem 20rem;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), transparent);

        > div {
            display: flex;
            gap: 90rem;

            ${media("<desktop")} {
                > div {
                    min-width: calc(50% - 40rem);
                }

                flex-wrap: wrap;
                gap: 50rem;
            }

            ${media("<=phone")} {
                flex-direction: column;

                > div:nth-of-type(even) {
                    min-width: unset;
                    align-items: flex-end;
                    text-align: right;
                }
            }

            > div {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 15rem;

                svg {
                    width: 50rem;
                    height: 50rem;
                }

                .title {
                    font-size: 20rem;
                    color: ${({ theme }) => theme.text.dark};
                    font-family: "Gilroy-SemiBold", sans-serif;
                }

                .description {
                    color: ${({ theme }) => theme.text.normal};
                    font-size: 15rem;
                    line-height: 30rem;
                }
            }
        }
    }

    section.start-right {
        margin: 0 -20rem;
        padding: 100rem 20rem 100rem;
        border-top: 1px solid ${({ theme }) => theme.borders.light};

        .leader {
            .title {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 100%;
                margin: auto;
                gap: 20rem;
                text-align: center;

                .pretitle {
                    color: ${({ theme }) => theme.primary};
                    font-family: "Gilroy-Bold", sans-serif;
                    font-size: 20rem;
                }

                h3.title {
                    font-family: "Gilroy-Bold", sans-serif;
                    font-size: 50rem;
                    color: ${({ theme }) => theme.text.dark};
                }

                .subtitle {
                    font-size: 18rem;
                    line-height: 30rem;
                    color: ${({ theme }) => theme.text.normal};
                    max-width: 400rem;
                }
            }
        }

        .content {
            border-radius: 10rem;
            padding: 30rem 35rem;
            display: flex;
            flex-direction: column;

            > .tile {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 50rem 0;

                &:nth-of-type(even) {
                    flex-direction: row-reverse;
                }

                .left {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    .icon {
                        width: 30rem;
                        height: 30rem;
                        background: #eff6ff;
                        border-radius: 90%;

                        svg {
                            width: 30rem;
                            height: 30rem;
                            transform: scale(0.95);
                        }
                    }

                    h4 {
                        font-family: "Gilroy-Black", sans-serif;
                        font-size: 30rem;
                        color: ${({ theme }) => theme.primary};
                        margin: 0 0 10rem;
                        background: -webkit-linear-gradient(#60a5fa, #818cf8);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    h5 {
                        margin: 10rem 0;
                        font-size: 16rem;
                        letter-spacing: 2rem;
                        font-weight: 600;
                        color: #60a5fa;
                        text-transform: uppercase;
                    }

                    p {
                        font-size: 16rem;
                        line-height: 28rem;
                        margin: 0;
                        color: ${({ theme }) => theme.text.light};
                    }
                }

                > .right {
                    flex: 1 33%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .image {
                        position: relative;
                        z-index: 50;
                        border-radius: 15rem;
                        max-width: 450rem;

                        &.shadow {
                            box-shadow: #eff6ff 0px 50px 100px -20px,
                                #c7d2fe 0px 30px 60px -30px;
                        }
                    }
                }

                ${media("<desktop")} {
                    &:not(.ms) {
                        flex-direction: column;
                        text-align: center;
                        align-items: center;
                        gap: 50px;

                        .left {
                            max-width: 500rem;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
`

const FeaturesPhoneCalls = () => {
    return (
        <Page constrainedHeader constrainedFooter constrainedCta>
            <Helmet>
                <title>Make Phone Calls - A Modern Experience</title>

                <meta
                    name="title"
                    content="Make Phone Calls - A Modern Experience"
                />
                <meta
                    name="description "
                    content="A fully featured phone system, flexible and powerful in any situation, all from a modern and beautiful application."
                />

                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://23shout.com/features/phone-calls"
                />
                <meta
                    property="og:title"
                    content="Make Phone Calls - A Modern Experience"
                />
                <meta
                    property="og:description"
                    content="A fully featured phone system, flexible and powerful in any situation, all from a modern and beautiful application."
                />
                <meta
                    property="og:image"
                    content="https://23shout.com/social/og/og-feature-phone-calls.png"
                />

                <meta property="twitter:card" content="summary_large_image" />
                <meta
                    property="twitter:url"
                    content="https://23shout.com/features/phone-calls"
                />
                <meta
                    property="twitter:title"
                    content="Make Phone Calls - A Modern Experience"
                />
                <meta
                    property="twitter:description"
                    content="A fully featured phone system, flexible and powerful in any situation, all from a modern and beautiful application."
                />
                <meta
                    property="twitter:image"
                    content="https://23shout.com/social/og/og-feature-phone-calls.png"
                />
            </Helmet>
            <Container>
                <section className={"top-buttons pageConstrained"}>
                    <FlatButton size={"large"} active>
                        Overview
                    </FlatButton>
                    <Link to={"/features"} tabIndex={-1}>
                        <FlatButton size={"large"}>Features</FlatButton>
                    </Link>
                </section>
                <section className={"hero pageConstrained"}>
                    <div className={"left"}>
                        <h1>
                            A fully featured phone system, <span>flexible</span>{" "}
                            and <span>powerful</span> in{" "}
                            <span>any situation</span>.
                        </h1>
                        <h2>
                            Make and receive calls in any volume, route
                            intelligently, all from a modern and beautiful
                            application.
                        </h2>
                        <a href={"//app.23shout.com"} tabIndex={-1}>
                            <FlatButton color={"primary"} size={"larger"}>
                                Get started for free <Chevron />
                            </FlatButton>
                        </a>
                    </div>
                    <div className={"right"}>
                        <div className={"anim-container"}>
                            <div className={"phase-one"}>
                                <div className={"start-button"}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        fill="currentColor"
                                        style={{ marginRight: "10rem" }}
                                    >
                                        <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
                                    </svg>
                                    Start call
                                </div>
                            </div>
                            <div className={"phase-two"}>
                                <StaticImage
                                    src={
                                        "../../images/features/phone-calls/anim-gem-1.png"
                                    }
                                    alt={"Decorative"}
                                    className={"gem gem-1"}
                                    objectFit={"contain"}
                                    layout={"fixed"}
                                    placeholder={"none"}
                                    width={55}
                                    height={55}
                                    quality={100}
                                />
                                <StaticImage
                                    src={
                                        "../../images/features/phone-calls/anim-gem-2.png"
                                    }
                                    alt={"Decorative"}
                                    className={"gem gem-2"}
                                    objectFit={"contain"}
                                    layout={"fixed"}
                                    placeholder={"none"}
                                    width={55}
                                    height={55}
                                    quality={100}
                                />
                                <StaticImage
                                    src={
                                        "../../images/features/phone-calls/anim-gem-3.png"
                                    }
                                    alt={"Decorative"}
                                    className={"gem gem-3"}
                                    objectFit={"contain"}
                                    layout={"fixed"}
                                    placeholder={"none"}
                                    width={55}
                                    height={55}
                                    quality={100}
                                />
                                <StaticImage
                                    src={
                                        "../../images/features/phone-calls/anim-gem-4.png"
                                    }
                                    alt={"Decorative"}
                                    className={"gem gem-4"}
                                    objectFit={"contain"}
                                    layout={"fixed"}
                                    placeholder={"none"}
                                    width={55}
                                    height={55}
                                    quality={100}
                                />
                            </div>
                        </div>
                        <div className={"back"} />
                    </div>
                </section>

                <section className={"quick-feats"}>
                    <div className={"pageConstrained"}>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <g>
                                    <g>
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <path d="M21.9,7.89l-1.05-3.37c-0.22-0.9-1-1.52-1.91-1.52H5.05c-0.9,0-1.69,0.63-1.9,1.52L2.1,7.89C1.64,9.86,2.95,11,3,11.06V19 c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7.94C22.12,9.94,22.09,8.65,21.9,7.89z M13,5h1.96l0.54,3.52C15.59,9.23,15.11,10,14.22,10 C13.55,10,13,9.41,13,8.69V5z M6.44,8.86C6.36,9.51,5.84,10,5.23,10C4.3,10,3.88,9.03,4.04,8.36L5.05,5h1.97L6.44,8.86z M11,8.69 C11,9.41,10.45,10,9.71,10c-0.75,0-1.3-0.7-1.22-1.48L9.04,5H11V8.69z M18.77,10c-0.61,0-1.14-0.49-1.21-1.14L16.98,5l1.93-0.01 l1.05,3.37C20.12,9.03,19.71,10,18.77,10z" />
                                </g>
                            </svg>
                            <div className={"title"}>
                                Calls for any business
                            </div>
                            <div className={"description"}>
                                From receptions, to PT's, insurance companies,
                                and telemarketing, we offer a complete stack for
                                all your calls across the globe.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M19.46 10a1 1 0 0 0-.07 1 7.55 7.55 0 0 1 .52 1.81 8 8 0 0 1-.69 4.73 1 1 0 0 1-.89.53H5.68a1 1 0 0 1-.89-.54A8 8 0 0 1 13 6.06a7.69 7.69 0 0 1 2.11.56 1 1 0 0 0 1-.07 1 1 0 0 0-.17-1.76A10 10 0 0 0 3.35 19a2 2 0 0 0 1.72 1h13.85a2 2 0 0 0 1.74-1 10 10 0 0 0 .55-8.89 1 1 0 0 0-1.75-.11z" />
                                <path d="M10.59 12.59a2 2 0 0 0 2.83 2.83l5.66-8.49z" />
                            </svg>
                            <div className={"title"}>
                                Get started in minutes
                            </div>
                            <div className={"description"}>
                                Create an account online and use our contact
                                centre builder to generate a personalised
                                experience. You'll make your first call in
                                minutes.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <rect fill="none" height="24" width="24" />
                                <g>
                                    <path d="M18.04,7.99l-0.63-1.4l-1.4-0.63c-0.39-0.18-0.39-0.73,0-0.91l1.4-0.63l0.63-1.4c0.18-0.39,0.73-0.39,0.91,0l0.63,1.4 l1.4,0.63c0.39,0.18,0.39,0.73,0,0.91l-1.4,0.63l-0.63,1.4C18.78,8.38,18.22,8.38,18.04,7.99z M21.28,12.72L20.96,12 c-0.18-0.39-0.73-0.39-0.91,0l-0.32,0.72L19,13.04c-0.39,0.18-0.39,0.73,0,0.91l0.72,0.32L20.04,15c0.18,0.39,0.73,0.39,0.91,0 l0.32-0.72L22,13.96c0.39-0.18,0.39-0.73,0-0.91L21.28,12.72z M16.24,14.37l1.23,0.93c0.4,0.3,0.51,0.86,0.26,1.3l-1.62,2.8 c-0.25,0.44-0.79,0.62-1.25,0.42l-1.43-0.6c-0.2,0.13-0.42,0.26-0.64,0.37l-0.19,1.54c-0.06,0.5-0.49,0.88-0.99,0.88H8.38 c-0.5,0-0.93-0.38-0.99-0.88L7.2,19.59c-0.22-0.11-0.43-0.23-0.64-0.37l-1.43,0.6c-0.46,0.2-1,0.02-1.25-0.42l-1.62-2.8 c-0.25-0.44-0.14-0.99,0.26-1.3l1.23-0.93C3.75,14.25,3.75,14.12,3.75,14s0-0.25,0.01-0.37L2.53,12.7c-0.4-0.3-0.51-0.86-0.26-1.3 l1.62-2.8c0.25-0.44,0.79-0.62,1.25-0.42l1.43,0.6c0.2-0.13,0.42-0.26,0.64-0.37l0.19-1.54C7.45,6.38,7.88,6,8.38,6h3.23 c0.5,0,0.93,0.38,0.99,0.88l0.19,1.54c0.22,0.11,0.43,0.23,0.64,0.37l1.43-0.6c0.46-0.2,1-0.02,1.25,0.42l1.62,2.8 c0.25,0.44,0.14,0.99-0.26,1.3l-1.23,0.93c0.01,0.12,0.01,0.24,0.01,0.37S16.25,14.25,16.24,14.37z M13,14c0-1.66-1.34-3-3-3 s-3,1.34-3,3s1.34,3,3,3S13,15.66,13,14z" />
                                </g>
                            </svg>
                            <div className={"title"}>Optimise your process</div>
                            <div className={"description"}>
                                Protect yourself from fraud, and reduce time
                                spent managing your data using our powerful
                                built in tools.
                            </div>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" />
                            </svg>
                            <div className={"title"}>Scale globally</div>
                            <div className={"description"}>
                                With dozens of points of origin and teams
                                dedicated to ensuring successful routing over
                                the globe, 23shout makes it easy to launch new
                                markets and connect using local numbers.
                            </div>
                        </div>
                    </div>
                </section>

                <section className={"start-right"}>
                    <div className={"pageConstrained"}>
                        <div className={"leader"}>
                            <div className={"title"}>
                                <div className={"pretitle"}>
                                    Get started right
                                </div>
                                <h3 className={"title"}>
                                    Customise your experience
                                </h3>
                                <div className={"subtitle"}>
                                    23shout provides a contact centre builder
                                    that customises your new dialler exactly how
                                    you want it.
                                </div>
                            </div>
                        </div>

                        <div className={"content"}>
                            <div className={"tile"}>
                                <div className={"left"}>
                                    <div className={"icon"}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            fill="url(#brandGradient)"
                                        >
                                            <rect
                                                fill="none"
                                                height="24"
                                                width="24"
                                            />
                                            <path d="M3,6C2.45,6,2,5.55,2,5V2c0-0.55,0.45-1,1-1h3c0.55,0,1,0.45,1,1S6.55,3,6,3H4v2C4,5.55,3.55,6,3,6z M17,2 c0,0.55,0.45,1,1,1h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1h-3C17.45,1,17,1.45,17,2z M3,18c-0.55,0-1,0.45-1,1v3 c0,0.55,0.45,1,1,1h3c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1H4v-2C4,18.45,3.55,18,3,18z M17,22c0,0.55,0.45,1,1,1h3 c0.55,0,1-0.45,1-1v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v2h-2C17.45,21,17,21.45,17,22z M19,18c0,1.1-0.9,2-2,2H7c-1.1,0-2-0.9-2-2V6 c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2V18z M9,9c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,8,9,8.45,9,9z M9,12c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,11,9,11.45,9,12z M9,15c0,0.55,0.45,1,1,1h4 c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,14,9,14.45,9,15z" />
                                        </svg>
                                    </div>
                                    <h5>Integrated scripts</h5>
                                    <h4>
                                        Add your custom brand scripts to your
                                        calls
                                    </h4>
                                    <p>
                                        Simplify calls for your team using{" "}
                                        <Link to={"/features/call-scripts"}>
                                            <TextButton color={"primary"}>
                                                Scripts
                                            </TextButton>
                                        </Link>
                                        . You can create as many as you like and
                                        select which calls should show which,
                                        adding in common elements like contacts
                                        names, emails, locations, and more.
                                    </p>
                                </div>
                                <div className={"right"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/phone-calls/scripts.png"
                                        }
                                        alt={"Call scripts"}
                                        className={"image shadow"}
                                        objectFit={"contain"}
                                        layout={"constrained"}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                </div>
                            </div>

                            <div className={"tile"}>
                                <div className={"left"}>
                                    <div className={"icon"}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            fill="url(#brandGradient)"
                                        >
                                            <g>
                                                <path d="M20,9L20,9c0-0.55-0.45-1-1-1h-3V5c0-0.55-0.45-1-1-1h0c-0.55,0-1,0.45-1,1v3h-4V5c0-0.55-0.45-1-1-1h0C8.45,4,8,4.45,8,5 v3H5C4.45,8,4,8.45,4,9v0c0,0.55,0.45,1,1,1h3v4H5c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h3v3c0,0.55,0.45,1,1,1h0 c0.55,0,1-0.45,1-1v-3h4v3c0,0.55,0.45,1,1,1h0c0.55,0,1-0.45,1-1v-3h3c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h-3v-4h3 C19.55,10,20,9.55,20,9z M14,14h-4v-4h4V14z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <h5>Phone numbers on demand</h5>
                                    <h4>
                                        Utilise new numbers around the globe
                                        with a few clicks
                                    </h4>
                                    <p>
                                        Call from where your customers live
                                        using out global{" "}
                                        <Link to={"/features/phone-numbers"}>
                                            <TextButton color={"primary"}>
                                                phone number inventory
                                            </TextButton>
                                        </Link>
                                        . Find suitable local, mobile, and toll
                                        free numbers and customise their inbound
                                        and outbound behaviour.
                                    </p>
                                </div>
                                <div className={"right"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/phone-calls/numbers.png"
                                        }
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"constrained"}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                </div>
                            </div>

                            <div className={"tile"}>
                                <div className={"left"}>
                                    <div className={"icon"}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            fill="url(#brandGradient)"
                                        >
                                            <rect
                                                fill="none"
                                                height="24"
                                                width="24"
                                            />
                                            <path d="M3,6C2.45,6,2,5.55,2,5V2c0-0.55,0.45-1,1-1h3c0.55,0,1,0.45,1,1S6.55,3,6,3H4v2C4,5.55,3.55,6,3,6z M17,2 c0,0.55,0.45,1,1,1h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1h-3C17.45,1,17,1.45,17,2z M3,18c-0.55,0-1,0.45-1,1v3 c0,0.55,0.45,1,1,1h3c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1H4v-2C4,18.45,3.55,18,3,18z M17,22c0,0.55,0.45,1,1,1h3 c0.55,0,1-0.45,1-1v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v2h-2C17.45,21,17,21.45,17,22z M19,18c0,1.1-0.9,2-2,2H7c-1.1,0-2-0.9-2-2V6 c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2V18z M9,9c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,8,9,8.45,9,9z M9,12c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,11,9,11.45,9,12z M9,15c0,0.55,0.45,1,1,1h4 c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-4C9.45,14,9,14.45,9,15z" />
                                        </svg>
                                    </div>
                                    <h5>Smarter scheduling</h5>
                                    <h4>
                                        Smart call priority and scheduling based
                                        on activity
                                    </h4>
                                    <p>
                                        With our built in smart{" "}
                                        <Link
                                            to={
                                                "/collaboration/contact-management"
                                            }
                                            tabIndex={-1}
                                        >
                                            <TextButton color={"primary"}>
                                                Contact Management
                                            </TextButton>
                                        </Link>{" "}
                                        and outcome system, you can easily
                                        manage data and create segments for
                                        different stages in your pipeline.
                                    </p>
                                </div>
                                <div className={"right"}>
                                    <StaticImage
                                        src={
                                            "../../images/features/phone-calls/prioritize.png"
                                        }
                                        alt={"Call scripts"}
                                        className={"image"}
                                        objectFit={"contain"}
                                        layout={"constrained"}
                                        placeholder={"none"}
                                        quality={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"leader"} style={{ marginTop: "100px" }}>
                        <div className={"title"}>
                            <div className={"pretitle"}>
                                More ways we're different
                            </div>
                        </div>
                    </div>
                    <section className={"quick-feats"}>
                        <div className={"pageConstrained"}>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#brandGradient)"
                                >
                                    <g>
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                            x="0"
                                        />
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M19.46,8l0.79-1.75L22,5.46c0.39-0.18,0.39-0.73,0-0.91l-1.75-0.79L19.46,2c-0.18-0.39-0.73-0.39-0.91,0l-0.79,1.75 L16,4.54c-0.39,0.18-0.39,0.73,0,0.91l1.75,0.79L18.54,8C18.72,8.39,19.28,8.39,19.46,8z M11.5,9.5L9.91,6 C9.56,5.22,8.44,5.22,8.09,6L6.5,9.5L3,11.09c-0.78,0.36-0.78,1.47,0,1.82l3.5,1.59L8.09,18c0.36,0.78,1.47,0.78,1.82,0l1.59-3.5 l3.5-1.59c0.78-0.36,0.78-1.47,0-1.82L11.5,9.5z M18.54,16l-0.79,1.75L16,18.54c-0.39,0.18-0.39,0.73,0,0.91l1.75,0.79L18.54,22 c0.18,0.39,0.73,0.39,0.91,0l0.79-1.75L22,19.46c0.39-0.18,0.39-0.73,0-0.91l-1.75-0.79L19.46,16 C19.28,15.61,18.72,15.61,18.54,16z" />
                                        </g>
                                    </g>
                                </svg>
                                <div className={"title"}>Beautiful calling</div>
                                <div className={"description"}>
                                    We're not that solution that runs on Windows
                                    95, we're designed from the ground up to
                                    fill the void for a modern contact centre.
                                </div>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#brandGradient)"
                                >
                                    <rect fill="none" height="24" width="24" />
                                    <g>
                                        <path d="M19.83,7.5l-2.27-2.27c0.07-0.42,0.18-0.81,0.32-1.15c0.11-0.26,0.15-0.56,0.09-0.87C17.84,2.49,17.14,1.99,16.4,2 c-1.59,0.03-3,0.81-3.9,2l-5,0C4.46,4,2,6.46,2,9.5c0,2.25,1.37,7.48,2.08,10.04C4.32,20.4,5.11,21,6.01,21L8,21c1.1,0,2-0.9,2-2v0 h2v0c0,1.1,0.9,2,2,2l2.01,0c0.88,0,1.66-0.58,1.92-1.43l1.25-4.16l2.14-0.72c0.41-0.14,0.68-0.52,0.68-0.95V8.5c0-0.55-0.45-1-1-1 H19.83z M12,9H9C8.45,9,8,8.55,8,8v0c0-0.55,0.45-1,1-1h3c0.55,0,1,0.45,1,1v0C13,8.55,12.55,9,12,9z M16,11c-0.55,0-1-0.45-1-1 c0-0.55,0.45-1,1-1s1,0.45,1,1C17,10.55,16.55,11,16,11z" />
                                    </g>
                                </svg>
                                <div className={"title"}>
                                    No lock in contracts
                                </div>
                                <div className={"description"}>
                                    There's no tricks, we're confident you'll
                                    like 23shout. Subscriptions are billed
                                    monthly, and if you're not happy we'll even
                                    pro-rata your time back to you.
                                </div>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill={"url(#brandGradient)"}
                                >
                                    <g>
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                    </g>
                                    <g>
                                        <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M19.46,9.12l-2.78,1.15 c-0.51-1.36-1.58-2.44-2.95-2.94l1.15-2.78C16.98,5.35,18.65,7.02,19.46,9.12z M12,15c-1.66,0-3-1.34-3-3s1.34-3,3-3s3,1.34,3,3 S13.66,15,12,15z M9.13,4.54l1.17,2.78c-1.38,0.5-2.47,1.59-2.98,2.97L4.54,9.13C5.35,7.02,7.02,5.35,9.13,4.54z M4.54,14.87 l2.78-1.15c0.51,1.38,1.59,2.46,2.97,2.96l-1.17,2.78C7.02,18.65,5.35,16.98,4.54,14.87z M14.88,19.46l-1.15-2.78 c1.37-0.51,2.45-1.59,2.95-2.97l2.78,1.17C18.65,16.98,16.98,18.65,14.88,19.46z" />
                                    </g>
                                </svg>
                                <div className={"title"}>Here to help</div>
                                <div className={"description"}>
                                    You're not left to figure it out, let's
                                    arrange a call anytime to help your team
                                    succeed with 23shout. No request is too
                                    large or small.
                                </div>
                            </div>
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="url(#brandGradient)"
                                >
                                    <g>
                                        <rect
                                            fill="none"
                                            height="24"
                                            width="24"
                                        />
                                        <path d="M9.78,11.16l-1.42,1.42c-0.68-0.69-1.34-1.58-1.79-2.94l1.94-0.49C8.83,10.04,9.28,10.65,9.78,11.16z M10.15,5.15 L7.35,2.35c-0.2-0.2-0.51-0.2-0.71,0L3.85,5.15C3.54,5.46,3.76,6,4.21,6h1.81C6.04,6.81,6.1,7.54,6.21,8.17l1.94-0.49 C8.08,7.2,8.03,6.63,8.02,6h1.78C10.24,6,10.46,5.46,10.15,5.15z M20.15,5.15l-2.79-2.79c-0.2-0.2-0.51-0.2-0.71,0l-2.79,2.79 C13.54,5.46,13.76,6,14.21,6h1.78c-0.1,3.68-1.28,4.75-2.54,5.88c-0.5,0.44-1.01,0.92-1.45,1.55c-0.34-0.49-0.73-0.88-1.13-1.24 L9.46,13.6C10.39,14.45,11,15.14,11,17c0,0,0,0,0,0h0v4c0,0.55,0.45,1,1,1h0c0.55,0,1-0.45,1-1v-4c0,0,0,0,0,0 c0-2.02,0.71-2.66,1.79-3.63c1.38-1.24,3.08-2.78,3.2-7.37h1.8C20.24,6,20.46,5.46,20.15,5.15z" />
                                    </g>
                                </svg>
                                <div className={"title"}>
                                    Keep your data clean
                                </div>
                                <div className={"description"}>
                                    Setup call <strong>Outcomes</strong> with
                                    automated actions. Automatically schedule no
                                    answers to be called again in the future, or
                                    add numbers to Do Not Call lists.
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </Container>
        </Page>
    )
}

const Chevron = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className={"bottom-link-chevron"}
        >
            <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
    )
}

export default FeaturesPhoneCalls
